<mat-toolbar class="fx-height-42 fz-16" color="primary">
	<mat-icon fontSet="mdi" fontIcon="mdi-currency-usd"></mat-icon>
	<h3 class="fz-16" style="padding-left: 4px">
		{{ 'PAYMENT' | translate }} -
		{{ form.get('patientName').valueChanges | async }}
	</h3>
	<span class="fx-grow-1"></span>
	<button mat-icon-button [matDialogClose]="null" tabindex="-1">
		<mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
	</button>
</mat-toolbar>

<mat-dialog-content>
	<div [formGroup]="form" class="fx-layout-column payment-dialog">
		<div class="fx-layout-row fx-content-space-between fx-gap-4">
			<mat-form-field style="width: 140px">
				<mat-label>{{ 'PAYMENT_DATE' | translate }}</mat-label>
				<input
					matInput
					[matDatepicker]="picker"
					placeholder="DD/MM/YYYY"
					formControlName="date"
					tabindex="-1" />
				<mat-datepicker-toggle
					matSuffix
					[for]="picker"></mat-datepicker-toggle>
				<mat-datepicker #picker></mat-datepicker>
			</mat-form-field>

			<mat-form-field style="width: 140px">
				<mat-label>{{ 'DUE_DATE' | translate }}</mat-label>
				<input
					matInput
					[matDatepicker]="picker2"
					placeholder="DD/MM/YYYY"
					formControlName="dueDate"
					tabindex="-1" />
				<mat-datepicker-toggle
					matSuffix
					[for]="picker2"></mat-datepicker-toggle>
				<mat-datepicker #picker2></mat-datepicker>
			</mat-form-field>

			<mat-form-field style="width: 80px" disabled>
				<mat-label>{{ 'BASE_TARIF' | translate }}</mat-label>
				<input
					matInput
					[placeholder]="'BASE_TARIF' | translate"
					formControlName="baseAmount"
					type="number"
					tabindex="-1" />
			</mat-form-field>

			<mat-form-field style="width: 80px" disabled>
				<mat-label>{{ 'DUE_AMOUNT' | translate }}</mat-label>
				<input
					matInput
					[placeholder]="'DUE_AMOUNT' | translate"
					formControlName="due"
					type="number"
					tabindex="-1" />
			</mat-form-field>
			<mat-form-field style="width: 68px">
				<mat-label>{{
						'DISCOUNT_PRICE' | translate: { currency: currencyFormat }
					}}
				</mat-label>
				<input
					matInput
					(keyup)="enterDiscount($event)"
					[placeholder]="'DISCOUNT_PRICE' | translate"
					formControlName="discount"
					type="number" />
			</mat-form-field>

			<mat-form-field style="width: 80px" disabled>
				<mat-label>{{
						'TOTAL_AMOUNT' | translate: { currency: currencyFormat }
					}}
				</mat-label>
				<input
					matInput
					[placeholder]="'TOTAL_AMOUNT' | translate"
					formControlName="totalAfterDiscount"
					type="number"
					tabindex="-1" />
			</mat-form-field>

			@if (form.get('payer').value === 'THIRD_PAYER') {
				<mat-form-field style="width: 80px">
					<mat-label
					>{{ 'ORG_PART' | translate }}({{
							currencyFormat
						}})
					</mat-label
					>
					<input
						matInput
						[placeholder]="'ORG_PART' | translate"
						formControlName="organismPart"
						type="number" />
				</mat-form-field>

				<mat-form-field style="width: 80px">
					<mat-label
					>{{ 'PAT_PART' | translate }}({{
							currencyFormat
						}})
					</mat-label
					>
					<input
						matInput
						[placeholder]="'PAT_PART' | translate"
						formControlName="patientPart"
						type="number" />
				</mat-form-field>
			}

			<mat-form-field style="width: 80px">
				<mat-label>{{ 'PAID_AMOUNT' | translate }}</mat-label>
				<input
					matInput
					[placeholder]="'PAID_AMOUNT' | translate"
					formControlName="enteredAmount"
					(blur)="enterAmount($event)"
					type="number" />
			</mat-form-field>
			<mat-form-field style="width: 80px">
				<mat-label>{{ 'LEFT_AMOUNT' | translate }}</mat-label>
				<input
					matInput
					[ngModel]="leftAmount"
					[ngModelOptions]="{ standalone: true }"
					[ngStyle]="{
						textDecoration: payment.paymentDispense
							? 'line-through'
							: ''
					}"
					[placeholder]="'LEFT_AMOUNT' | translate"
					disabled />
			</mat-form-field>
		</div>

		<div
			class="fx-layout-row fx-content-space-between fx-items-end fx-gap-4">
			<div class="fx-layout-column-nowrap">
				<h5 style="margin: 0 8px">{{ 'PAYER' | translate }}</h5>

				<mat-radio-group
					class="fx-layout-row fx-content-start fx-items-end"
					formControlName="payer">
					@for (option of ['PATIENT', 'THIRD_PAYER', 'OTHER'];
						track option) {
						<mat-radio-button class="fx-grow-1" [value]="option">
							{{ option | translate }}
						</mat-radio-button>
					}
				</mat-radio-group>
			</div>
			<span class="fx-grow-1"></span>

			<div class="fx-gap-4">
				@switch (form.get('payer').valueChanges | async) {
					@case ('THIRD_PAYER') {
						<div
							class="fx-grow-1 fx-layout-row-nowrap fx-content-space-between fx-gap-4">
							<mat-form-field class="fx-grow-1">
								<mat-label>{{
										'INS_NAME' | translate
									}}
								</mat-label>
								<input
									[matAutocomplete]="organismAuto"
									[placeholder]="'INS_NAME' | translate"
									aria-label="Insurance organism"
									formControlName="organismName"
									matInput
									[required]="organismRequired"
									type="text" />
								<mat-autocomplete
									#organismAuto="matAutocomplete"
									(optionSelected)="onSelectOrganismNew()"
									autoActiveFirstOption>
									@for (organism of filteredOrganisms | async;
										track organism) {
										<mat-option [value]="organism.name">
											{{ organism.name }}
										</mat-option>
									}
								</mat-autocomplete>
							</mat-form-field>

							@if (selectedConventionString) {
								<mat-form-field style="width: 160px">
									<mat-label>{{
											'CONVENTION' | translate
										}}
									</mat-label>
									<mat-select
										[placeholder]="'CONVENTION' | translate"
										[required]="organismRequired"
										[disabled]="performedPayment"
										[(ngModel)]="selectedConventionString"
										[ngModelOptions]="{ standalone: true }"
										(selectionChange)="
											onSelectConvention($event)
										">
										@for (convention of conventions;
											track convention) {
											<mat-option [value]="convention">
												{{
													orgAttrByIdx(convention, 0)
												}}
											</mat-option>
										}
									</mat-select>
								</mat-form-field>

								<mat-form-field style="width: 80px">
									<mat-label
									>{{
											'ORG_PART' | translate
										}}(%)
									</mat-label
									>
									<input
										matInput
										[placeholder]="'ORG_PART' | translate"
										disabled
										[(ngModel)]="
											selectedConvention.organismPartPercentage
										"
										[ngModelOptions]="{
											standalone: true
										}" />
								</mat-form-field>

								<mat-form-field style="width: 80px">
									<mat-label
									>{{
											'PAT_PART' | translate
										}}(%)
									</mat-label
									>
									<input
										matInput
										[placeholder]="'PAT_PART' | translate"
										disabled
										[(ngModel)]="
											selectedConvention.patientPartPercentage
										"
										[ngModelOptions]="{
											standalone: true
										}" />
								</mat-form-field>
							}
						</div>
					}
					@case ('OTHER') {
						<mat-form-field style="width: 160px" [formGroup]="form">
							<mat-label>{{
									'PAYER_NAME' | translate
								}}
							</mat-label>
							<input
								matInput
								[placeholder]="'PAYER_NAME' | translate"
								formControlName="otherPayer" />
						</mat-form-field>

						<mat-form-field style="width: 160px" [formGroup]="form">
							<mat-label>{{ 'PAYER_ID' | translate }}</mat-label>
							<input
								matInput
								[placeholder]="'PAYER_ID' | translate"
								formControlName="payerID" />
						</mat-form-field>
					}
				}
			</div>
		</div>

		<div
			class="fx-layout-row fx-content-space-between fx-items-center fx-gap-4">
			<mat-form-field class="fx-grow-1">
				<mat-label>{{ 'PAYMENT_METHOD' | translate }}</mat-label>
				<mat-select
					[placeholder]="'PAYMENT_METHOD' | translate"
					formControlName="paymentMethodId">
					@for (item of paymentMethods; track item) {
						<mat-option [value]="item.id">
							{{ item.value }}
						</mat-option>
					}
				</mat-select>
			</mat-form-field>

			<mat-form-field class="fx-grow-1">
				<mat-label>{{ 'REFERENCE' | translate }}</mat-label>
				<input
					matInput
					[placeholder]="'REFERENCE' | translate"
					formControlName="reference" />
			</mat-form-field>
			<mat-form-field class="fx-grow-1">
				<mat-label>{{ 'BANK' | translate }}</mat-label>
				<mat-select
					[placeholder]="'BANK' | translate"
					formControlName="bankId">
					@for (item of banks; track item) {
						<mat-option [value]="item.id">
							{{ item.value }}
						</mat-option>
					}
				</mat-select>
				<button
					matSuffix
					mat-icon-button
					[disabled]="performedPayment"
					(click)="$event.stopImmediatePropagation(); addBank()">
					<mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
				</button>
			</mat-form-field>
		</div>

		<mat-form-field>
			<mat-label>{{ 'COMMENT' | translate }}</mat-label>
			<textarea
				matInput
				[placeholder]="'COMMENT' | translate"
				formControlName="notes"></textarea>
		</mat-form-field>

		@if (paymentItems) {
			<div class="ft-payment-table">
				<div
					class="ft-row ft-header"
					[style.border-bottom-color]="performedPayment ? 'grey' : ''">
					<div class="ft-cell">{{ 'CODE' | translate }}</div>
					<div class="ft-cell">{{ 'DESIGNATION' | translate }}</div>
					<div class="ft-cell">{{ 'PRICE' | translate }}</div>
					<div class="ft-cell">{{ 'ORG_PART' | translate }}</div>
					<div class="ft-cell">{{ 'PAT_PART' | translate }}</div>
					<div class="ft-cell">{{ 'DISCOUNT' | translate }}</div>
				</div>
				@for (item of paymentItems; track item.accessionNumber) {
					<!--<div class="ft-row">
						<div class="ft-cell">{{ item.accessionNumber }}</div>
						<div class="ft-cell fx-layout-row-nowrap fx-content-start fx-items-center fx-gap-4">
							<span>{{ item.procedureCode }}</span>
						</div>
						&lt;!&ndash;<div (blur)="changeItemValue('price', item, $event)" [contentEditable]="!performedPayment"
							 class="ft-cell">{{ item.price }}
						</div>&ndash;&gt;
						<div class="ft-cell">{{ item['price'] }}</div>
						<div class="ft-cell">{{ item['organismPartPrice'] }}</div>
						<div class="ft-cell">{{ item['patientPartPrice'] }}</div>
						<div class="ft-cell">{{ item['discount'] }}</div>
						&lt;!&ndash;<div (blur)="changeItemValue('discount', item, $event)" [contentEditable]="!performedPayment"
							 class="ft-cell">{{ item.discount }}
						</div>&ndash;&gt;
					</div>-->
					<div class="ft-row">
						<div class="ft-cell">{{ item.accessionNumber }}</div>
						<div
							class="ft-cell fx-layout-row-nowrap fx-content-start fx-items-center fx-gap-4">
							<span>{{ item.procedureCode }}</span>
							<mat-icon mat-ripple *ngIf="!performedPayment"
									  (click)="editProcedureCode(item)"
									  fontIcon="mdi-pencil" fontSet="mdi"
									  style="font-size: 16px !important;color: #00aaff;cursor: pointer;"></mat-icon>
						</div>
						<div (blur)="changeItemValue('price', item, $event)" [contentEditable]="!performedPayment"
							 class="ft-cell">
							{{ item.price }}
						</div>
						<div class="ft-cell">
							{{ item['organismPartPrice'] }}
						</div>
						<div class="ft-cell">
							{{ item['patientPartPrice'] }}
						</div>
						<div
							(blur)="changeItemValue('discount', item, $event)"
							[contentEditable]="!performedPayment"
							class="ft-cell">
							{{ item.discount }}
						</div>
					</div>
				}
			</div>
		}
	</div>
</mat-dialog-content>

<mat-dialog-actions align="end" [formGroup]="form">
	<mat-slide-toggle
		formControlName="paymentDispense"
		style="font-size: 12px; font-weight: 400"
		tabindex="-1">
		{{ 'PAYMENT_DISPENSE' | translate }}
	</mat-slide-toggle>
	<span class="fx-grow-1"></span>
	<button [mat-dialog-close]="null" color="warn" mat-raised-button>
		{{ 'EXIT' | translate }}
	</button>
	@if (performedPayment && profile?.canDeletePayment) {
		<button mat-raised-button color="warn" (click)="cancelPayment()">
			{{ 'CANCEL_PAYMENT' | translate }}
		</button>
	}
	@if (performedPayment) {
		<button
			(click)="printPaymentReceipt()"
			color="primary"
			mat-raised-button>
			<mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
			{{ 'PAYMENT_RECEIPT' | translate }}
		</button>
	}
	@if (!performedPayment) {
		<button
			(click)="createPayment()"
			color="primary"
			mat-raised-button
			[disabled]="form.invalid">
			{{ 'SAVE' | translate }}
		</button>
	}
</mat-dialog-actions>
